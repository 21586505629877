.cardContainer {
    position: relative;
    overflow: hidden;
  }
  
  .hoverIndicator {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%; /* half the container width */
    z-index: 2;
    opacity: 0;
  }
  
  .hoverIndicator:hover + .glowingCard {
    transform: rotateY(10deg); /* Default rotation if right side is hovered first */
  }
  
  /* If left side is hovered first, apply a different rotation */
  .hoverIndicator:hover + .hoverIndicator + .glowingCard {
    transform: rotateY(-10deg); 
  }
  
  .glowingCard {
    transition: transform 0.3s, box-shadow 0.5s;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px #0000003b;
  }
  
  .glowingCard:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22), 0 0 0 6px rgba(255, 255, 255, 0.8);
  }
  
  .navbar-custom {
    background-color: rgba(0, 0, 0, 0.384);
    position: sticky;
    top: 0;
    z-index: 1000;
    transition: background-color 0.9s;
  }
  
  .nav-link-custom {
    color: white;
    cursor: pointer;
    transition: color 0.3s;  /* Smooth transition */
  }
  
  .nav-link-custom:hover {
    color: rgb(0, 255, 255);  /* Color when hovered over */
  }

  .icon-hover {
    transition: transform 0.3s, color 0.3s;
  }
  
  .icon-hover:hover {
    transform: scale(1.2);
    color: gold; 
  }

  body, html {
    margin: 0;
    padding: 0;
  }
  
  .project-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  .project-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .project-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .project-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
  }
  .arrow {
  cursor: pointer;
  font-size: 2em;
  position: absolute;
  top: 50%;
  color: white; /* White arrow icons */
  transition: transform 0.3s; /* Smooth hover effect */
}
.arrow:hover {
  transform: translateY(-50%) scale(1.2); /* Scale arrow icons on hover */
}
.arrow-left {
  left: 10px;
}
.arrow-right {
  right: 10px;
}

.fading {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.card-content {
  transition: height 0.5s ease-in-out;
}

.card {
  transition: opacity 0.5s;
  opacity: 1;
}

.fading:hover {
  opacity: 0;
}

.java-badge {
  background-color: #F94144 !important;
}

.c-badge {
  background-color: #F3722C !important;
}

.python-badge {
  background-color: #F8961E !important;
}

.js-badge {
  background-color: #F9844A !important;
}

.html-badge {
  background-color: #F9C74F !important;
}

.spring-badge {
  background-color: #90BE6D !important;
}

.mysql-badge {
  background-color: #43AA8B !important;
}

.mongo-badge {
  background-color: #577590 !important;
}

.skill-badge {
  cursor: pointer;
  transition: transform 0.3s;
}

.skill-badge:hover {
  transform: scale(1.1);
}

  
  